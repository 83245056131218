'use client';

import { isProduction } from '@magicschool/utils/nextjs/metadata';
import dynamic from 'next/dynamic';
const INTERCOM_APP_ID = 'j8v9i2vs';
export const USE_INTERCOM = process.env.NEXT_PUBLIC_USE_INTERCOM === 'true';
const IntercomProvider = dynamic(() => import('react-use-intercom').then(mod => mod.IntercomProvider), {
  ssr: false
});
export const EnvironmentSensitiveWrapperClient = ({
  children
}: React.PropsWithChildren) => {
  if (isProduction() || USE_INTERCOM) {
    return <IntercomProvider appId={INTERCOM_APP_ID}>{children}</IntercomProvider>;
  }
  return children;
};