import { getBuildPrefix } from '@magicschool/utils/nextjs/metadata';
import { type User, captureException, setUser, withScope } from '@sentry/nextjs';

export enum LogLevel {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

// Using JSONStringify will output the content using JSON.stringify which might be what you want but results in unformatted (and untruncated)
export enum OutputStrategy {
  JSONStringify = 'JSONStringify',
  Decorate = 'Decorate',
}

type ILogLevel = {
  level: number;
  type: LogLevel;
  prefix: string;
  color: string;
  bg: string;
};

type ILogUser = {
  id: string;
  email: string;
};

export class Level implements ILogLevel {
  level: number;
  type: LogLevel;
  prefix: string;
  color: string;
  bg: string;

  constructor(level: number, type: LogLevel, prefix: string, color: string, bg: string) {
    this.level = level;
    this.type = type;
    this.prefix = prefix;
    this.color = color;
    this.bg = bg;
  }
}

// these exist because JavaScript enums are just strings or numbers, really.
export const DEBUG = new Level(10, LogLevel.DEBUG, '🪲 debug 🪲', 'grey', 'white');
export const INFO = new Level(20, LogLevel.INFO, '❗ info ❗', 'black', 'grey');
export const WARN = new Level(40, LogLevel.WARN, '⚠️ warn ⚠️', 'black', 'pink');
export const ERROR = new Level(50, LogLevel.ERROR, '🚑 error 🚑', 'black', 'red');

export class Logger {
  public path = '';
  public pathKey = '';
  public userId = '';
  public maxChars = 2000;
  public ipAddress: string | undefined;
  public outputStrategy: OutputStrategy = OutputStrategy.Decorate;
  public build = '';

  // biome-ignore lint/suspicious/noExplicitAny: We don't care about the type of val
  debug(msg: string, ...data: any[]): void {
    this._logMessage(DEBUG, msg, data);
  }

  // biome-ignore lint/suspicious/noExplicitAny: We don't care about the type of val
  info(msg: string, ...data: any[]): void {
    this._logMessage(INFO, msg, data);
  }

  // biome-ignore lint/suspicious/noExplicitAny: We don't care about the type of val
  warn(msg: string, ...data: any[]): void {
    this._logMessage(WARN, msg, data);
  }

  // biome-ignore lint/suspicious/noExplicitAny: We don't care about the type of val
  error(msg: string, ...data: any[]): void {
    this._logMessage(ERROR, msg, data);
  }

  private _indent(depth: number): string {
    return '  '.repeat(depth);
  }

  // biome-ignore lint/suspicious/noExplicitAny: We don't care about the type of val
  private _stringify(val: any, maxDisplayLength: number): string {
    let out = val.toString();
    if (typeof val === 'string') {
      out = `'${val}'`;
    }
    return out.length > maxDisplayLength ? `${out.substr(0, maxDisplayLength)}...'` : out;
  }

  // this turned into a beast. recurse through [object object] and pretty print the output but basically it should turn:
  // {id:'crap', inner:{id:'morecrap'}}
  // into
  // {
  //   id: 'crap',
  //   inner:
  //   {
  //     id: 'morecrap'
  //   }
  // }
  private _objectify(obj: object, maxDepth = 2, currentDepth = 0, maxDisplayLength = -1): string {
    // biome-ignore lint/suspicious/noExplicitAny: We don't care about the type of val
    if (typeof obj !== 'object') return (obj as any).toString();
    let stringified = `${this._indent(currentDepth)}{\n`;
    const keys: string[] = Object.keys(obj);
    for (const [idx, key] of keys.entries()) {
      // biome-ignore lint/suspicious/noExplicitAny: We don't care about the type of val
      const val: any = obj[key as keyof typeof obj];
      if (typeof val === 'object' && Object.keys(val).length && currentDepth < maxDepth) {
        stringified += `${this._indent(currentDepth + 1)}${key}:${this._objectify(val, maxDepth, currentDepth + 1, maxDisplayLength)}\n`;
      } else {
        stringified += `${this._indent(currentDepth + 1)}${key}: ${this._stringify(val, maxDisplayLength)}`;
        stringified += idx + 2 <= keys.length ? ',\n' : '\n';
      }
    }
    return `${stringified}${this._indent(currentDepth)}}`;
  }

  // biome-ignore lint/suspicious/noExplicitAny: We don't care about the type of val
  private _arrayify(arr: any[], maxDepth = 2, currentDepth = 0, maxDisplayLength = -1): string {
    let stringed = '[';
    for (const element of arr) {
      if (Array.isArray(element) && currentDepth < maxDepth) {
        stringed += `${this._arrayify(element, maxDepth, currentDepth + 1, maxDisplayLength)}, `;
      } else {
        stringed += `${this._objectify(element, maxDepth, currentDepth + 1, maxDisplayLength)}, `;
      }
    }
    stringed = stringed.replace(/, $/, ''); // Remove trailing comma and space
    stringed += ']';
    return stringed;
  }

  // biome-ignore lint/suspicious/noExplicitAny: We don't care about the type of val
  private _decorate(data: any[]): string {
    const decorated: string[] = [];
    for (const datum of data) {
      const type = typeof datum;
      switch (type) {
        case 'object':
          if (datum === null) {
            decorated.push('[null]');
          } else if (Array.isArray(datum)) {
            decorated.push(`\n${this._arrayify(datum, 3, 0, this.maxChars)}`);
          } else if (datum.constructor && datum.constructor !== Object) {
            const className = datum.constructor.name;
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            const properties: { [key: string]: any } = {};

            for (const key of Object.getOwnPropertyNames(datum)) {
              properties[key] = datum[key];
            }

            for (const key of Object.getOwnPropertyNames(Object.getPrototypeOf(datum))) {
              if (key !== 'constructor' && typeof datum[key] !== 'function') {
                properties[key] = datum[key];
              }
            }

            decorated.push(`\n${className} ${this._objectify(properties, 3, 0, this.maxChars)}`);
          } else {
            const val = `\n${this._objectify(datum, 3, 0, this.maxChars)}`;
            decorated.push(val);
          }
          break;
        case 'undefined':
          decorated.push('[undefined]');
          break;
        case 'string':
          decorated.push(`'${datum}'`);
          break;
        default:
          decorated.push(datum.toString());
          break;
      }
    }
    return decorated.join(', ');
  }

  private _getFullTimestamp(): string {
    const pad = (n: number, s = 2) => `${new Array(s).fill(0)}${n}`.slice(-s);
    const d = new Date();

    return `${pad(d.getFullYear(), 4)}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(
      d.getSeconds(),
    )}.${pad(d.getMilliseconds(), 3)}`;
  }

  // biome-ignore lint/suspicious/noExplicitAny: We don't care about the type of val
  private _output(content: any): string {
    let output = '';
    if (this.outputStrategy === OutputStrategy.Decorate) {
      // _decorate is failing sometimes, this is a temporary fallback
      try {
        output = this._decorate(content);
      } catch (_e) {
        output = JSON.stringify(content);
      }
    } else {
      output = JSON.stringify(content);
    }
    return output;
  }

  // instead of using console.dir, we write our own so that objects can be output in a single string.
  // biome-ignore lint/suspicious/noExplicitAny: We don't care about the type of val
  private _logMessage(logLevel: ILogLevel, msg: string, data: any[] = []): void {
    const build = this.build || getBuildPrefix();
    const file = Logger.getCallerFilePath() ?? this.pathKey;
    // each time we log, check to see if the log level has been overridden via config setting
    const logLevelForPath = Logger.getPathLogLevel(this.pathKey);
    const stamp = this._getFullTimestamp();
    if (logLevel.level >= logLevelForPath.level) {
      if (typeof window !== 'undefined') {
        const userId = this.userId || '(n/a)';
        console.groupCollapsed(`[${stamp}] [${logLevel.prefix}] [${file}] ${msg}`);
        console[logLevel.type](
          `%c[${stamp}] ${logLevel.prefix} - ${file}, user-id: ${userId}, build: ${build}.`,
          `background: ${logLevel.bg}; color: ${logLevel.color};`,
          msg,
          this._output(data),
        );
        console.groupEnd();
      } else {
        const userId = this.userId || '(n/a)';
        const ipAddress = this.ipAddress || '(no-ip)';
        console[logLevel.type](
          `[${stamp}::${ipAddress}] ${logLevel.prefix} - ${file}, user-id: ${userId}, build: ${build}.`,
          msg,
          this._output(data),
        );
      }
    }
    // send logged errors to sentry explicitly
    if (logLevel.level >= ERROR.level && process.env.NEXT_PUBLIC_LOGGING_SEND_ERRORS_TO_SENTRY === 'true') {
      withScope?.((scope) => {
        scope.setExtra('data', this._output(data));
        captureException?.(msg);
      });
    }
  }

  // These are for error logging - actually setting a user on the error logging scope, NOT used outside of Sentry/error reporting
  // Keeping them here as a wrapper in case we ditch Sentry.
  getLogUser = (): ILogUser | null => {
    return withScope?.((scope) => {
      const user: User | undefined = scope.getUser();
      if (user) {
        return {
          id: user.id,
          email: user.email,
        } as ILogUser;
      }
      return null;
    });
  };

  // Again, a sentry wrapper for setting user information
  setLogUser = (user: ILogUser | null) => {
    if (user) {
      setUser?.({
        id: user.id,
        email: user.email,
      });
    } else {
      setUser?.(null);
    }
  };

  // The following code grabs the types of loggers that are available and then checks environment variables to see if there are
  // path-specific log levels defined. They are comma separated strings that contain either url slugs, next pathnames, or router.asPath values
  private static levels = { DEBUG, INFO, WARN, ERROR };
  private static environmentClientLogLevel = process.env.NEXT_PUBLIC_LOGGING_CLIENT_DEFAULT_THRESHOLD as keyof typeof LogLevel;
  private static environmentServerLogLevel = process.env.NEXT_PUBLIC_LOGGING_SERVER_DEFAULT_THRESHOLD as keyof typeof LogLevel;
  static DEFAULT_CLIENT_LEVEL = Logger.levels[Logger.environmentClientLogLevel] || WARN;
  static DEFAULT_SERVER_LEVEL = Logger.levels[Logger.environmentServerLogLevel] || WARN;
  // Once we have any potential overrides, build up a map and convert the string to a Level so we get fancy formatting and all that
  private static pathOverrides: Record<string, Level> = {};
  static {
    if (process.env.NEXT_PUBLIC_LOGGING_PATH_THRESHOLD_DEBUG_OVERRIDE) {
      const debugOverrides = process.env.NEXT_PUBLIC_LOGGING_PATH_THRESHOLD_DEBUG_OVERRIDE?.split(',') ?? [];
      for (const pathname of debugOverrides) {
        Logger.pathOverrides[pathname.trim()] = DEBUG;
      }
    }

    if (process.env.NEXT_PUBLIC_LOGGING_PATH_THRESHOLD_INFO_OVERRIDE) {
      const infoOverrides = process.env.NEXT_PUBLIC_LOGGING_PATH_THRESHOLD_INFO_OVERRIDE?.split(',') ?? [];
      for (const pathname of infoOverrides) {
        Logger.pathOverrides[pathname.trim()] = INFO;
      }
    }
  }

  // This takes the current path and checks it against our map of overrides. If nothing exists, right now we default to the server level default
  // Todo: let it fallback to a client OR a server level
  static getPathLogLevel = (pathname: string): Level => {
    return Logger.pathOverrides[pathname] ?? (typeof window === 'undefined' ? Logger.DEFAULT_SERVER_LEVEL : Logger.DEFAULT_CLIENT_LEVEL);
  };

  private static _traceLineExtractor =
    /at\s+(\w+|.*\$1)\s+\((?:webpack-internal:\/\/\/\([^)]+\)\/|webpack-internal:\/\/\/)?\.\/(src\/(?!util\/Logger)[^:]+):\d+:\d+\)/;

  private static _findFirstMatchingLine(logLines: string[]): { word: string; path: string } | null {
    for (const line of logLines) {
      const match = line.match(Logger._traceLineExtractor);
      if (match) {
        return {
          word: match[1]?.replace('Object.', '')?.replace('$1', '') ?? '',
          path: match[2] ?? '',
        };
      }
    }
    return null;
  }

  static getCallerFilePath = () => {
    try {
      const stacktrace = new Error().stack;
      const traces = stacktrace?.split('\n') ?? [];
      const match = Logger._findFirstMatchingLine(traces);
      if (!match) return null;

      const { word, path } = match;
      if (['eval', 'handler'].includes(word)) {
        return `[${path}]`;
      }

      return `${word}[${path}]`;
    } catch {
      // Silently fail
    }
    return null;
  };
}

export const logger = new Logger();
