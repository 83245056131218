'use client';

import { isProduction } from '@magicschool/utils/nextjs/metadata';
import Script from 'next/script';
function CookieYesClient() {
  return <>
      {isProduction() && <Script src="https://cdn-cookieyes.com/client_data/643e0fb28246e9ffd93b5190/script.js" />}
      {!isProduction() && <div id="show-cookies" // @link https://nextjs.org/docs/app/building-your-application/optimizing/scripts#inline-scripts
    // biome-ignore lint/security/noDangerouslySetInnerHtml: Only happens in dev environments
    dangerouslySetInnerHTML={{
      __html: `<!-- <script src="https://cdn-cookieyes.com/client_data/643e0fb28246e9ffd93b5190/script.js"></script> -->`
    }} />}
    </>;
}
export default CookieYesClient;