'use client';

import { QueryClient, QueryClientProvider as QueryClientProviderBase, keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
const client = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 60000,
      // 1 minute
      staleTime: 60000 // 1 minute
    }
  }
});
const QueryClientProvider = (props: {
  children: React.ReactNode;
}) => <QueryClientProviderBase client={client} data-sentry-element="QueryClientProviderBase" data-sentry-component="QueryClientProvider" data-sentry-source-file="useQuery.tsx">{props.children}</QueryClientProviderBase>;
const queryFactory = {
  customPrompts: ['customPrompts'] as const,
  userFavorites: ['user_favorites'] as const,
  translationStatus: () => ['translation_status'] as const,
  userFavoritesOrdered: (userId: string) => ['user_favorites_ordered', userId] as const,
  userFeedback: (userId?: string) => ['user-feedback', userId].filter(Boolean),
  thread: (thread?: string) => ['thread', thread].filter(Boolean),
  threads: (thread?: string) => ['threads', thread].filter(Boolean),
  siteConfig: (userId?: string) => ['site-config', userId].filter(Boolean),
  searchOutputHistory: (filters?: unknown) => ['output_history_search', filters].filter(Boolean),
  searchOutputHistoryGenerations: (filters?: unknown) => ['output_history_search_generations', filters].filter(Boolean),
  searchExcerpts: (filters?: unknown) => ['excerpt_search', filters].filter(Boolean),
  userData: (userId?: string) => ['userData', userId].filter(Boolean),
  useUser: (userId?: string) => ['user', userId].filter(Boolean),
  useOrgDetails: (orgId?: string) => ['org_details', orgId].filter(Boolean),
  usePrevToolInputs: (uid: string, tool_id: string) => ['prev_inputs', uid, tool_id].filter(Boolean),
  userFavoriteByToolID: (uid: string, tool_id: string) => ['user_favorite_by_tool_id', uid, tool_id].filter(Boolean)
};
export { useQuery, QueryClientProvider, useQueryClient, keepPreviousData, client, queryFactory };