'use client';

import { logger } from '@magicschool/logger';
import { isProduction } from '@magicschool/utils/nextjs/metadata';
import type { accessiBeConfig, accessiBeLanguage, triggerSizes, triggerXPositions, triggerYPositions } from 'declarations/accessiBe';
import Script from 'next/script';
const config: accessiBeConfig = {
  statementLink: '',
  footerHtml: '',
  hideMobile: process.env.NEXT_PUBLIC_ACCESSIBE_HIDE_MOBILE ? process.env.NEXT_PUBLIC_ACCESSIBE_HIDE_MOBILE === 'true' : false,
  hideTrigger: process.env.NEXT_PUBLIC_ACCESSIBE_HIDE_TRIGGER ? process.env.NEXT_PUBLIC_ACCESSIBE_HIDE_TRIGGER === 'true' : false,
  disableBgProcess: false,
  language: 'en' as accessiBeLanguage,
  // default to english for now
  position: 'right',
  leadColor: '#146ff8',
  triggerColor: '#146ff8',
  triggerRadius: '50%',
  triggerPositionX: process.env.NEXT_PUBLIC_ACCESSIBE_DESKTOP_POSITION_X as triggerXPositions || 'right',
  triggerPositionY: process.env.NEXT_PUBLIC_ACCESSIBE_DESKTOP_POSITION_Y as triggerYPositions || 'bottom',
  triggerIcon: 'people',
  triggerSize: process.env.NEXT_PUBLIC_ACCESSIBE_DESKTOP_BUTTON_SIZE as triggerSizes || 'medium',
  triggerOffsetX: process.env.NEXT_PUBLIC_ACCESSIBE_DESKTOP_HORIZONTAL_OFFSET ? Number.parseInt(process.env.NEXT_PUBLIC_ACCESSIBE_DESKTOP_HORIZONTAL_OFFSET) : 20,
  triggerOffsetY: process.env.NEXT_PUBLIC_ACCESSIBE_DESKTOP_VERTICAL_OFFSET ? Number.parseInt(process.env.NEXT_PUBLIC_ACCESSIBE_DESKTOP_VERTICAL_OFFSET) : 20,
  mobile: {
    triggerSize: process.env.NEXT_PUBLIC_ACCESSIBE_MOBILE_BUTTON_SIZE as triggerSizes || 'small',
    triggerPositionX: process.env.NEXT_PUBLIC_ACCESSIBE_MOBILE_POSITION_X as triggerXPositions || 'right',
    triggerPositionY: process.env.NEXT_PUBLIC_ACCESSIBE_MOBILE_POSITION_Y as triggerYPositions || 'bottom',
    triggerOffsetX: process.env.NEXT_PUBLIC_ACCESSIBE_MOBILE_HORIZONTAL_OFFSET ? Number.parseInt(process.env.NEXT_PUBLIC_ACCESSIBE_MOBILE_HORIZONTAL_OFFSET) : 10,
    triggerOffsetY: process.env.NEXT_PUBLIC_ACCESSIBE_MOBILE_VERTICAL_OFFSET ? Number.parseInt(process.env.NEXT_PUBLIC_ACCESSIBE_MOBILE_VERTICAL_OFFSET) : 10,
    triggerRadius: '50%'
  }
};

// i was going to make a generic useScript hook but in is case it's so specific to accessiBe i'ma letitBe
function AccessiBeClient() {
  return <>
      {isProduction() && <Script src="https://acsbapp.com/apps/app/dist/js/app.js" async onLoad={() => {
      try {
        window.acsbJS.init(config);
      } catch (error) {
        // TODO: Maybe notify the user of this error?
        logger.error('Failed to initialize accessiBe:', error);
      }
    }}></Script>}
      {!isProduction() && <div style={{
      display: 'none'
    }} id="accessiBe-widget"
    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
    dangerouslySetInnerHTML={{
      __html: `<!-- <script src="https://acsbapp.com/apps/app/dist/js/app.js"></script> -->`
    }} />}
    </>;
}
export default AccessiBeClient;