'use client';

import { SCHOOL_COOKIE_NAME } from '@magicschool/utils/constants/cookies';
import { useEffect } from 'react';
const cookiesToDelete = [SCHOOL_COOKIE_NAME, ...Array.from({
  length: 6
}).map((_, i) => `${SCHOOL_COOKIE_NAME}.${i}`)];
export const CleanCookies = () => {
  useEffect(() => {
    for (const cookie of cookiesToDelete) {
      document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=magicschool.ai`;
    }
  }, []);
  return null;
};