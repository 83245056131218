import { getLoginUrl } from '@magicschool/utils/nextjs/url';

export const edlinkLaunchUrl = () => {
  const edlinkSSOLogin = new URL('/sso/login', 'https://ed.link');
  edlinkSSOLogin.searchParams.set('client_id', process.env.NEXT_PUBLIC_EDLINK_CLIENT_ID);
  edlinkSSOLogin.searchParams.set('redirect_uri', edlinkRedirectUri());
  edlinkSSOLogin.searchParams.set('response_type', 'code');

  return edlinkSSOLogin.href;
};

export const edlinkRedirectUri = () => {
  return new URL('/handle-edlink', getLoginUrl()).href;
};
